@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";

html,
body,
#root {
  height: 100%;
  width: 100%;
  
  button {
    font-weight: 600;
  }
}

body.bp4-dark {
  background-color: #383E47;
}

#root {
  overflow-y: auto;
}